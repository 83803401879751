.login {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .login-header {
    background-color: $header-color;
    color: white;
    height: 60px;
    padding-left: 15px;
    h3 {
      height: inherit;
      display: table-cell;
      margin: 0;
      vertical-align: middle;
      font-size: 23px;
    }
  }
  .login-box {
    @include container();
    max-width: 400px;
    width: 100%;
    background-color: #fff;
  }
  input {
    font-size: 16px;
  }
  .login-body {
    padding: 15px;
  }
  .form-group {
    width: 100%;
    margin-top: 10px;
  }
  .form-actions {
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
    button {
      width: 100%;
    }
  }
  .alert {
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 10px;
    min-height: 34px;
    padding: 7px;
    text-align: center;
  }
  .login-box h2 {
    margin-left: 40px;
  }

  .or-container {
    text-align: center;
    clear: both;
  }

  .or-line {
    margin-bottom: 0;
    position: relative;
    top: 21px;
    height: 0;
    border: 0;
    border-top: 1px solid #c4c4c4;
  }

  .or-text {
    display: inline-block;
    position: relative;
    padding: 10px;
    background-color: #fff;
    text-transform: lowercase;
  }

  .login-service-button {
    display: block;
    cursor: pointer;
    border-radius: 3px;
    padding: 8px 0;
    margin-bottom: 16px;
    border: 1px solid #c4c4c4;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }

  .login-service-sso {
    color: #fff;
    background-color: #0078d7;
    border-color: transparent;
  }
}
#passwordForm {
  .alert {
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 10px;
    min-height: 34px;
    padding: 7px;
    text-align: center;
  }
}
