.form-group {
  margin-bottom: 20px;
}

.ui-select-custom-spinner {
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 1000;
}

.spinner-with-clear {
  right: 41px;
}

// Fix to make ui select search box with clear button have same border radius on the left corners
.input-group .form-control:last-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.method-target-result-item {
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

// Makes ui-select choices appear correctly in modals when using append-to-body
.ui-select-bootstrap.open {
  z-index: 1100 !important;
}
