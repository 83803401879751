// Paddings

.p-0 {
  padding: 0;
}

.m-5px {
  margin: 5px;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pr-md-base {
  @include medium-screen-up {
    padding-right: 12px !important;
  }
}

.pl-md-base {
  @include medium-screen-up {
    padding-left: 12px !important;
  }
}

.pb-1,
.py-1 {
  padding-bottom: 10px;
}

.pt-1,
.py-1 {
  padding-top: 10px;
}

.py-2 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-base,
.py-base,
.pt-base {
  padding-top: 6px;
}

.p-base,
.py-base,
.pb-base {
  padding-bottom: 6px;
}

.p-base,
.px-base,
.pl-base {
  padding-left: 12px;
}

.p-base,
.px-base,
.pr-base {
  padding-right: 12px;
}

.pl-lg-base {
  @include large-screen-up {
    padding-left: 12px !important;
  }
}

.pr-lg-base {
  @include large-screen-up {
    padding-right: 12px !important;
  }
}

// Margins

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.margin-top-5 {
  margin-top: 5px;
}

.mb-base {
  margin-bottom: 6px;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.m-base,
.mx-base,
.ml-base {
  margin-left: 12px;
}

.m-base,
.mx-base,
.mr-base {
  margin-right: 12px;
}

.m-base,
.my-base,
.mt-base {
  margin-top: 6px;
}

.m-base,
.my-base,
.mb-base {
  margin-bottom: 6px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2px {
  margin-bottom: 2px;
}
