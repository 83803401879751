.file-browser {
  @include container(10px);
  &:focus {
    outline: 0;
  }
}
.file-explorer {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.file-explorer-nav {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
}
.file-list-view {
  margin-top: 10px;
  thead,
  tbody {
    border: 1px solid $border-color;
  }
  .one-row-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.1;
    text-overflow: ellipsis;
  }
  .type-name-column {
    display: flex;
    align-items: center;
    .file {
      margin-right: 5px;
      color: $file-color;
    }
    .dir {
      margin-right: 5px;
      color: $folder-color;
    }
  }
  table {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 5px;
    .name-column {
      width: auto;
    }
    .size-column {
      width: 100px;
      @include small-screen {
        width: 23%;
      }
    }
    .changed-column {
      width: 165px;
      @include small-screen {
        width: 37%;
      }
    }
  }
}

.files-per-page-pagination-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 5px;
}
.file-pagination {
  a {
    color: black;
    float: left;
    padding: 6px 12px;
    line-height: 1.428571429;
    text-decoration: none;
    border: 1px solid #e8e8e8;
  }
  a.active {
    background-color: rgba(0, 0, 0, 0.03);
  }

  a:hover:not(.active) {
    background-color: rgba(0, 0, 0, 0.03);
  }
}
.dir-path {
  margin-left: 10px;
  vertical-align: middle !important;
}
.card-selected {
  color: $selected-dark !important;
  .file,
  .dir {
    color: $selected-dark !important;
  }
}
.arrow-disabled {
  color: #bcbcbc;
  cursor: auto;
}
.deckgrid {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 15px;
  width: 100%;
  min-height: 100px;
  border-style: solid;
  border-color: #bbb;
  border-width: 1px;
}
.flex-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card {
  background: #fff center 30px no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 84px;
  margin: 5px;
  p {
    word-wrap: break-word;
    font-size: 9pt;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .file {
    text-align: center;
    color: $file-color;
  }
  .dir {
    text-align: center;
    color: $folder-color;
  }
  .file-extension {
    text-align: center;
    position: absolute;
    display: block;
    word-break: break-all;
    width: 30px;
    left: 27px;
    top: 15px;
    font-size: 12px;
    line-height: 1;
    color: white;
  }
}
