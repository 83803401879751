.header {
  background-color: $header-color;
  color: white;
  padding: 10px;
  box-shadow: 0px 5px 2px $shadow-color;
}

.glyphicon-triangle-bottom {
  cursor: pointer;
}

.logo-user-wrapper {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 6px;
  @include small-screen {
    justify-content: space-between;
  }
}

.custom-logo {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  padding: 3px;
  img {
    cursor: pointer;
    width: 42px;
    height: auto;
  }
  @include medium-screen-up {
    img {
      width: 54px;
      height: auto;
    }
  }
}

.custom-logo-middle {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  padding: 3px;
  img {
    cursor: pointer;
    width: auto;
    height: 27px;
  }
  @include medium-screen-up {
    img {
      width: auto;
      height: 27px;
    }
  }
}

.logo {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  @include small-screen {
    flex: 1 1 0px;
  }
}

.page-title {
  display: inline-block;
  cursor: pointer;
  flex: 1 1 0px;
  user-select: none;

  &-with-logo {
    @include medium-screen-up {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
    }
  }

  @include medium-screen-up {
    margin-top: auto;
    margin-bottom: auto;
  }
  @include small-screen {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
}
.search > .search-input {
  border-radius: 10px;
  margin-right: 5px;
}

.user-admin {
  display: flex;
  flex: 1 1 0px;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  @include medium-screen-up {
    margin-left: auto;
  }
}

.user-dropdown {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: none;
  align-items: center;
  border: none;
  p {
    margin: 0 5px 0 0;
  }
  .fa-user {
    margin-right: 5px;
  }
}

.menu-task-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tasks-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  min-width: 200px;
  @include medium-screen {
    min-width: 0px;
    .organization-select {
      display: none;
    }
    .language-select {
      display: none;
    }
  }
  @include small-screen {
    min-width: 0px;
    .organization-select {
      display: none;
    }
    .language-select {
      display: none;
    }
    .fa,
    .fas {
      display: none;
    }
    .unseen-text {
      display: none;
    }
  }
  .fa,
  .fas,
  .far {
    color: #fff;
    margin-right: 2px;
    margin-left: 2px;
    cursor: pointer;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.notification-icon {
  @include large-screen-up {
    position: relative;
  }
  @include medium-screen {
    position: relative;
  }
  cursor: pointer;
  .unseen-text {
    width: auto;
    position: absolute;
    color: white;
    height: 12px;
    top: -5px;
    right: -3px;
    border-radius: 2px;
    padding-right: 10%;
    padding-left: 10%;
    background-color: $step-fail;
    font-size: 10px;
    margin-left: 10px;
  }
}

.hamburger {
  display: none;
  user-select: none;
  @include medium-screen-down {
    margin-right: 5px;
    display: inline;
    cursor: pointer;
  }
  @include small-screen {
    position: absolute;
    top: 19px;
  }
}

.active-hamburger {
  display: none;
  @include medium-screen-down {
    display: block !important;
    min-width: 180px;
  }
}

.language-select {
  min-width: 100px;
}

.organization-member-select {
  max-width: 100px;
  min-width: 76px;
  margin-left: 10px;
}

.username {
  display: inline;
  @include small-screen {
    display: none;
  }
}
