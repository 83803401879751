.event-view {
  margin: 5px;
  padding: 5px;
  .table {
    position: relative;
    overflow-x: auto;
    .failed {
      background-color: $task-validation-bg-red;
      color: $task-validation-text-red;
    }
    .success {
      background-color: $task-validation-bg-green;
      color: $task-validation-text-green;
    }
  }
  .submit-button {
    @include small-screen {
      margin-top: 5px;
    }
  }
  .list-view-table {
    position: relative;
    overflow-x: auto;
    thead,
    tbody {
      border: 1px solid $border-color;
    }
  }
}

.events-status-wrapper {
  @include container;
  &:focus {
    outline: 0;
  }
}

.events-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-left: 10px;
  .status-note {
    max-width: 125px;
  }

  @include small-screen {
    flex-direction: column;
    margin-left: 0;
    .form-group {
      width: 100%;
    }
    .status-note {
      width: 100%;
      max-width: 100%;
    }
  }
}
.events-form .submit-button {
  @include small-screen {
    margin: 0;
  }
}

.events-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 6px;
  .signature {
    width: 250px;
    height: 35px;
    margin-left: 5px;
    margin-right: 5px;
    resize: none;

    @include small-screen {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }
}
