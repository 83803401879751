.ip-approval,
.prepare-sip {
  .submit-sip-eventlog-form {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-left: 10px;
  }
  .not-selectable {
    user-select: none;
  }
  .drop-zone {
    width: 100%;
    height: 100px;
    border-style: dotted;
    border-color: $border-color;
    text-align: center;
    vertical-align: middle;
    line-height: 100px;
  }
}
