@use 'sass:color';

.btn-dark {
  background-color: color.adjust($header-color, $lightness: -5%);
  color: white;
  border-color: color.adjust($header-color, $lightness: -7%);
  &:hover,
  &:focus {
    color: white;
    border-color: color.adjust($header-color, $lightness: -6%);
    background-color: color.adjust($header-color, $lightness: -4%);
  }
  outline: 0;
}

.btn-small {
  padding: 0px 16px;
  width: auto;
}

.help-button {
  color: $border-color;
  padding: 0 5px;
  display: inline;
}
