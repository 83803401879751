body.modal-open {
  overflow: hidden; // Remove scroll on main page when modal is open
  padding-right: 0 !important; // Prevents bootstrap from setting margin-right
}

.modal-heading {
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  position: relative;
  width: 100%;
  justify-content: space-between;
}

.modal-footer {
  background-color: #f4f4f4;
  padding: 10px;
}

.modal-title {
  width: 100%; //Fixes overflow in ie10
}

.preserve-modal {
  .request-type {
    width: 100% !important;
  }
}
/* a container with flex-direction column */
.main-container {
  height: 75vh; /* or position:absolute; height:100%; */
  display: flex;
  flex-direction: column;
}

/* an arbitrary container of fixed height */
.fixed-container {
  height: 100px;
  padding: 20px;
  background-color: #0b5ab0;
  color: white;
}

/* this is the flex container that will take the rest of the height */
.content-wrapper {
  display: flex;
  flex: 1;
  min-height: 0px; /* IMPORTANT: you need this for non-chrome browsers */
}

/* the container for our overflowed content */
.overflow-container {
  flex: 1;
  overflow: auto;
}

/* the overflow content itself */
.overflow-content {
  height: 2000px;
  color: black;
  padding: 20px;
}
