@use 'sass:color';

.resize-grabber {
  height: 100%;
  width: 1px;
  left: 6px;
  background-color: #eee;
  position: relative;
}

.rg-right {
  margin-right: -7px;
  &:hover {
    .resize-grabber {
      background-color: color.adjust(#eee, $lightness: -10%);
    }
  }
}
