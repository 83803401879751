.navigation {
  @include container(5px);
  height: 100%;
  width: 100%;
  margin-top: 5px;
}
.shortcut-nav-calendar-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
  float: left;
  overflow: hidden;
  margin: 5px 0 0 0;
  height: 600px;
  background-color: transparent;
  @include medium-screen-down {
    cursor: pointer;
    height: 100%;
    max-width: 0;
    overflow: visible;
    padding: 0;
    display: none;
    position: fixed;
    left: -10px;
    top: 105px;
    z-index: 100;
    .navigation {
      background-color: white;
    }
  }
}
.shortcut-list {
  width: 100%;
  float: left;
  height: 40px;
}
.shortcut-list button {
  border-radius: 20px;
}
.calendar {
  display: flex;
  flex-direction: column;
  height: 40%;
  width: 100%;
  margin-top: 10px;
  border-style: solid;
  border-color: #aaa;
}
