.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 3px;
  }
}

.align-items-center {
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content- {
  &end {
    justify-content: flex-end;
  }
  &between {
    justify-content: space-between;
  }
  &evenly {
    justify-content: space-evenly;
  }
}

.flex-100-grow {
  display: flex;
  width: 100%;
  div {
    flex-grow: 1;
  }
}
