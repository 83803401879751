@use 'sass:color';

// Selected table item
.selected {
  background-color: $selected !important;
}

.selectable {
  cursor: pointer;
  &:hover {
    background-color: color.adjust($selected, $lightness: 40%) !important;
  }
  &.selected {
    &:hover {
      background-color: $selected !important;
    }
  }
}

.global-search {
  min-width: 105px;
  flex-grow: 1;
  max-width: 500px;
}

.items-per-page {
  width: 100px !important;
  min-width: 55px;
  margin-right: 10px;
}

.prepare-dip-button {
  min-width: 168px;
}

// Refresh button
.fa-sync-alt-animate.ng-animate {
  animation: spin 0.7s infinite linear;
}
.fa-sync-alt-animate-once {
  animation: spin 0.7s;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: middle;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 1;
}

.pagination {
  margin: 5px 0;
}

.nav-refresh-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  .file-view-options {
    display: flex;
    flex-direction: row;
  }
  .button-refresh-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .refresh-filebrowser {
    overflow: hidden;
    padding: 3px 0;
    min-width: 20px;
    min-height: 18px;
    margin-left: 10px;
  }
}

.advanced-filters {
  position: absolute;
  display: block;
  border-style: solid;
  border-width: 1px;
  border-color: $border-color;
  max-width: 400px;
  max-height: none;
  overflow-y: auto;
  background-color: white;
  padding: 10px;
  z-index: 1000;
  margin-top: 24px;
  left: 0;
  @include medium-screen-up {
    left: 11px;
  }
  .formly-buttons-wrapper {
    display: flex;
    align-items: flex-end;
  }
  .formly {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-height: none;
    justify-content: flex-start;
  }
  .form-group {
    margin-bottom: 0px !important;
  }
  .button-group {
    display: flex;
    align-items: flex-end;
  }
  .plus-button {
    height: 26px;
    margin: 0px !important;
  }
  .formly-field {
    width: 100% !important;
    margin-bottom: 5px !important;
    .has-error {
      margin-bottom: 14px !important;
    }
  }
  .filter-action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .btn-default {
      margin-left: 3px;
    }
  }
}

.filter-icon {
  cursor: pointer;
  margin: 5px;
  border-style: solid;
  border-color: white;
  border-width: 1.5px;
  &:hover {
    border-color: rgba(102, 102, 102, 0.4);
  }
  &:active {
    border-color: rgba(102, 102, 102, 0.4);
  }
}

.filter-active {
  color: $step-success;
}

.blue-border {
  border-color: rgba(102, 102, 102, 0.4);
}

.table-container {
  position: relative;
  thead,
  tbody {
    border: 1px solid $border-color;
  }
}

.list-view {
  @include container(10px);
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .list-view-table {
    position: relative;
    overflow-x: auto;
    thead,
    tbody {
      border: 1px solid $border-color;
    }
  }
}

.list-view-buttons {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 5px;
}

.list-view-buttons .btn {
  margin-left: 10px;
}

.table-x-overflow {
  overflow-x: auto;
}

.object-identifier-value {
  display: inline-block;
}

.generation-indicator,
.new-version-indicator {
  display: inline-block;
  padding: 3px 5px;
  font-size: 12px;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
}

.generation-indicator {
  background-color: $gold-leaf;
}

.new-version-indicator {
  background-color: $step-fail;
}

.read-only {
  font-size: 0.7em;
}

.prepare-button {
  margin-left: 5px;
}

.empty-table-indicator {
  text-align: center;
  color: #757575;
}

.loading-column:after {
  content: '...';
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  text-align: left;
  animation: ellipsis-dot 1s infinite 0.3s;
  animation-fill-mode: fowards;
  width: 1.25em;
}

@keyframes ellipsis-dot {
  25% {
    content: '';
  }
  50% {
    content: '.';
  }
  75% {
    content: '..';
  }
  100% {
    content: '...';
  }
}

.refresh-icon {
  padding: 3px 0 3px 3px;
  overflow: hidden;
  min-width: 21px;
  flex-grow: 1;
  align-self: center;
  .fa-sync-alt {
    float: right;
    line-height: 0.75em;
  }
}

.search-prepare-wrapper {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  flex-wrap: nowrap;
  padding: 5px 0 5px 0;
  overflow-x: auto;
  .btn-primary {
    margin-left: 10px;
    padding: 2px 12px;
  }
  .organization-select {
    min-width: 75px;
  }
  > *:nth-last-child(2) {
    margin-right: 10px;
  }
}

.progress {
  height: 20px;
  width: 150px;
  margin: 0;
  background-color: #aaa;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.48);
}

.progress b {
  position: relative;
}

.column-label {
  cursor: pointer;
}

.tree-grid-row:focus {
  background-color: $selected;
  outline: none;
}

.title-action-button-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include medium-screen-up {
    flex-direction: row;
    justify-content: space-between;
  }
  .title {
    text-align: center;
    @include medium-screen-up {
      text-align: left;
    }
    display: flex;
    align-items: center;
  }
  .action-button {
    @include small-screen {
      width: 100%;
      .btn {
        width: 100%;
      }
    }
  }
}

.row-error {
  background-color: $step-fail !important;
  color: white;
}

.row-warning {
  background-color: $gold-leaf !important;
}

td,
th {
  &.shrink {
    width: 0.1%;
    white-space: nowrap;
  }
}

.cross-failure {
  color: $task-validation-text-red;
}

.check-success {
  color: $task-validation-text-green;
}
