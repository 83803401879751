.dashboard-stats {
  margin-top: 5px;
  hr {
    border-top-width: 1px;
    width: 100%;
  }
}
.dashboard-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border-collapse: collapse;
}

.dashboard-row {
  justify-content: space-between;
  &:before,
  &:after {
    content: '';
    display: block;
  }
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
