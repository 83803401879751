@use 'sass:color';

$body-background: #f5f5f5;
$shadow-color: #dfdfdf;
$border-color: #cecece;
$container-background: white;
$header-color: #0e4971;
$step-fail: #f44242;
$step-success: #4fc388;
$step-in-progress: #4d94f2;
$selected: rgba(41, 128, 185, 0.3);
$selected-dark: rgba(41, 128, 185, 0.7);
$sub-menu: #152935;
$gold-leaf: #d9b310;
$sky-blue: #328cc1;
$gray: #696969;
$gray-dark: #595959;
$light-blue: #f2f8fd;
$folder-color: #fea93e;
$file-color: #999;
$remove-discrete: #eea5a5;
$remove-discrete-hover: color.adjust($remove-discrete, $lightness: -30%);

// Task validation list
$task-validation-bg-green: #dff0d8;
$task-validation-bg-red: #f2dede;
$task-validation-bg-yellow: #fcf8e3;
$task-validation-text-green: #5a8b69;
$task-validation-text-red: #c64a48;
$task-validation-text-yellow: #d19e61;
