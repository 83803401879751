.menu {
  display: inline-block;
  @include small-screen {
    width: 100%;
    height: 100%;
  }
  .nav-item {
    cursor: pointer;
    user-select: none;
    margin: 0 15px 0 0 !important;
    white-space: nowrap;
    color: inherit;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: 0;
      text-decoration: none;
      color: #b4caf8;
      &:after {
        color: #b4caf8;
      }
    }
  }
}

.header .nav {
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12pt;
  .sliding-middle-out {
    display: inline-block;
    position: relative;
    padding-bottom: 3px;
  }
  .sliding-middle-out:after {
    content: '';
    display: block;
    margin: auto;
    height: 1px;
    width: 0px;
    background: transparent;
  }
  .chosen:hover:after {
    width: 100%;
    height: 1px;
    background: #fff;
  }
  .chosen:after {
    width: 100%;
    height: 1px;
    background: #fff;
  }
  @include small-screen {
    overflow-x: auto;
  }
}
