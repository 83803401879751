.user-settings {
  @include container(10px);
  margin-top: 10px;
  width: 100%;
  .ip-view-type {
    margin-bottom: 10px;
  }
  .column-picker {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    .buttons {
      display: flex;
      flex-direction: column;
    }
    align-items: center;
    select {
      width: 150px;
    }
  }
  .main-page-selects {
    .organization-select {
      display: none;
      @include medium-screen-down {
        display: block;
      }
    }

    .organization-select,
    .language-select {
      width: 50%;
      max-width: 400px;
      margin-bottom: 10px;
      @include small-screen {
        width: 100%;
      }
    }
  }
  .main-view-options {
    display: none;
    @include small-screen {
      display: inline-block;
      margin-bottom: 10px;
    }
  }
  .alert {
    width: 50%;
    @include small-screen {
      width: 100%;
    }
  }
}
